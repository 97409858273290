.md-margin--none {
	margin-bottom: 0;
}

.md-margin--small {
	margin-bottom: 10px;
}

.md-margin--medium {
	margin-bottom: 20px;
}

.md-margin--large {
	margin-bottom: 40px;
}

@media @screen-md-min {

	.md-margin--small {
		margin-bottom: 15px;
	}

	.md-margin--medium {
		margin-bottom: 30px;
	}

	.md-margin--large {
		margin-bottom: 60px;
	}
}

.topdown-margin--none {
	margin: 0;
}

.topdown-margin--small {
	margin: 10px 0;
}

.topdown-margin--medium {
	margin: 20px 0;
}

.topdown-margin--large {
	margin: 40px 0;
}

@media @screen-md-min {

	.topdown-margin--small {
		margin: 15px 0;
	}

	.topdown-margin--medium {
		margin: 30px 0;
	}

	.topdown-margin--large {
		margin: 60px 0;
	}
}

.sr-only {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    border: 0;
    clip-path: inset(50%);

    white-space: nowrap;

    &:active, &:focus {
        position: relative;

        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;

        clip-path: none;

        white-space: inherit;
    }
}