.number-animation {
    color: @oebb-red;
    padding: 5rem 2rem 3rem 2rem;

    position: relative;
    display: inline-block;

    &__content {
        display: flex;
        font-size: 4rem;
        font-weight: 900;
        gap: 0.2rem;
    }

    &__prefix {
        font-size: 3rem;
    }

    &__number {
        font-size: 5rem;
        text-shadow: 1px 0 @oebb-red, -1px 0 @oebb-red, 0 1px @oebb-red, 0 -1px @oebb-red;
        padding-bottom: 0.75rem;

        @media @screen-xs-max {
            font-size: 3rem;
        }
    }

    &__percentage {
        font-size: 4rem;
    }

    &__suffix {
        font-size: 1.5rem;
        margin-top: 1.2rem;
        font-weight: 700;
    }

    &__description {
        font-size: 1.1rem;
        margin-top: 0.5rem;
        display: block;
    }

    &-container {
        position: relative;
        display: inline-block;
    }

    &__content-wrapper {
        display: inline-block;
        position: relative;
        padding-right: 130px;
    }

    &__badge {
        position: absolute;
        top: 30%;
        right: 0;
        transform: translateY(-50%) rotate(-5deg);
        width: 85px;
        height: 85px;
        background-color: @oebb-red;
        color: white;
        font-size: 28px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        line-height: 1;

        @media @screen-xs-max {
            right: 25px;
        }

        &--value {
            display: block;
        }

        &--percentage {
            display: block;
            font-size: 20px;
        }
    }
}
