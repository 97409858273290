.header {
    background: @white;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.18);
    position: relative;
    z-index: 10;

    // Custom Styling for RCG Blog
    .blog & {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        transition: transform .75s ease;
    }

    .blog .scroll--down & {
        transform: translateY(-100%);
    }

    @media @screen-md-min {
        .blog .scroll--down & {
            transform: translateY(-100%) translateY(55px);
        }
    }

    &--independent {
        padding: 12px 0 12px;

        @media @screen-md-min {
            padding: 38px 0 55px;
        }
    }
}

.header-sticky {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
}

.logo {
    display: inline-block;
    vertical-align: middle;

    &:hover {
        text-decoration: none;
    }

    &-wrapper {
        @media @screen-md-min {
            flex-basis: 100%;
            margin-top: 12px;
            margin-bottom: 12px;

            .header--nav-inline & {
                flex-basis: auto;
                margin-right: 60px;
            }
        }
    }

    &__item {
        width: 120px;
        height: auto;
        display: inline-block;
        vertical-align: middle;

        .logo-wrapper--custom & {
            width: 65px;
        }

        @media @screen-md-min {
            width: 200px;

            .logo-wrapper--custom & {
                width: 100px;
            }
        }

        @media @screen-lg-min {
            width: 265px;
        }
    }

    &__title {
        display: inline-block;
        vertical-align: middle;

        color: @black-90;
        font-family: @font-light;
        font-size: 18px;
        font-style: normal;

        @media @screen-xs-min {
            font-size: 24px;
        }

        @media @screen-md-min {
            font-size: 36px;
        }

        .header--nav-inline & {
            vertical-align: bottom;
        }
    }

    .mgnlEditorBar {
        display: block;
    }
}

.logo__item + .logo__title {
    margin-left: 10px;

    @media @screen-md-min {
        margin-left: 20px;
    }
}

.service-nav {
    display: none;

    @media @screen-md-min {
        display: block;
        margin-bottom: -10px;
        list-style: none;
        text-align: right;

        &__list-item {
            display: inline-block;
            margin-left: 20px;
            font-family: @font-medium;
            font-size: 14px;
            letter-spacing: 0.3px;
            line-height: 18px;
            position: relative;
            z-index: 1;

            &--newsletter { width: 185px; }
            &--linkedin { width: 115px; }
            &--rcg { width: 90px; }
        }

        &__link {
            color: @black-80;
            padding: 13px 0 7px;
            display: block;
        }
    }
}

.newsletter-servicelink {
    background: @oebb-red;
    padding-left: 10px;
    padding-right: 10px;
    color: @white;
    font-family: @font-bold;
    transform: skew(-22deg);
    transform-origin: bottom;
    text-align: center;

    span {
        transform: skew(22deg);
        display: block;
    }

    &:hover {
        background: @dark-red;
        text-decoration: none;
    }
}

.search-toggler {
    display: block;
    width: 26px;
    height: 50px;
    text-align: center;
    position: relative;
    right: 0;
    margin-left: auto;
    padding: 12px 0;
    color: @black-90;

    &:focus,
    &:hover,
    &:active {
        color: @oebb-red;
    }

    .obb-icon-search {
        font-size: 22px;
    }

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: @oebb-red;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: width .35s ease;
    }

    &--active {
        color: @oebb-red;

        &:after {
            width: 100%;
            color: @oebb-red;
        }
    }

    @media @screen-md-min {
        position: absolute;
        height: auto;
        padding: 16px 0;
        bottom: 0;
        line-height: 1em;

        .header--nav-inline & {
            padding: 10px 0;
        }
    }
}