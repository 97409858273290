.hero-banner {
    position: relative;
    overflow: hidden;
}

.hero-banner__canvas_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.hero-banner .main {
    padding: 0;
}

.hero-banner__canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
}

.hero-banner__canvas_left {
    @media @screen-md-min {
        height: 180%;
        left: 85px;
        bottom: -80px;
        background-color: rgba(@oebb-red);
        transform-origin: bottom left;
        transform: rotate(42.5deg) scaleX(-1);
        z-index: 9;
    }
}

.hero-banner__canvas_right {
    @media @screen-md-min {
        height: 140%;
        right: -220px;
        top: 0;
        background-color: rgba(@oebb-red);
        transform-origin: top right;
        transform: rotate(42.5deg) scaleX(-1);
        z-index: 9;
    }
}

.hero-banner__img {

    img {
        display: block;
    }

    .hero-banner__img--variation {
        display: none;
    }

    @media @screen-sm-max {
        height: ~'calc(100vh - 110px)';

        > img {
            max-width: none;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }

        .hero-banner__img--xs-max {
            display: block;
        }
       
        .hero-banner__img--sm-max.hero-banner__img--hide-xs,
        .hero-banner__img--hide-xs {
            display: none;
        }
    }

    @media @screen-sm-max {
        .hero-banner__img--sm-max {
            display: block;
        }
        
        .hero-banner__img--hide-sm {
            display: none;
        }
    }


}

.hero-banner__link:hover,
.hero-banner__link:focus {
    text-decoration: none;
}

.hero-banner__text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 0 0 10px;
    z-index: 5;
    max-width: 415px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 10;

    @media @screen-md-min {
        left: 180px;
        right: auto;
        transform: translateX(-40%);
    }
}



.hero-banner__title {
    color: @white;
    margin-bottom: 5px;
    font-size: 32px;
    line-height: 1.25;
    font-family: @font-medium;
    font-weight: 600;

    @media @screen-md-min {
        font-size: ~'calc(16px + 16 * ((100vw - 320px)/ 960))';
    }

}

.hero-banner__subtitle, .hero-banner__subtitle--bottom {
    color: @white;
    font-size: 18px;
    margin-bottom: 18px;

    @media @screen-md-min {
        font-size: 22px;
    }
}

.hero-banner__subtitle h2 {
    font-size: 1.5rem;
    width: 600px;

    @media @screen-md-min {
        font-size: 3rem;
    }
}

.hero-banner__subtitle__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.hero-banner__subtitle--bottom {
    font-weight: 700;
    margin-top: auto;
}
