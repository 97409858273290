.video-teaser {
    
    &__wrapper {
        background-color: @white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        transition: box-shadow .15s;
        
        &:hover,
        &:focus {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
        }

        &--horizontal {

            &--right {
                flex-direction: row-reverse!important;
            }

            @media @screen-md-min {
                display: flex;
                flex-direction: row;
                align-items: stretch;

                .video-teaser__container {
                    flex: 1;
                    max-width: 33.33%;
                    display: flex;
                    align-items: stretch;
                    height: 100%;
                    min-height: 350px;
                    padding-bottom: 0;
                }
        
                .video-teaser__text {
                    flex: 2;
                    max-width: 66.66%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .video-preview {
                    flex: 1;
                    display: flex;
                    align-items: stretch;
                    height: unset;
                }
                
                .video-preview-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: right;
                    display: block;
                }
            }        
        }
    }

    &__container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 ratio */
        height: 0;
        overflow: hidden;
    }

    &__text {
        padding: 1rem 1.25rem 0.5rem 1.25rem
    }

    &__blockquote {
        @media @screen-md-min {
            min-height: 245px;
        }

        @media @screen-lg-min {
            min-height: 200px;
        }
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.video-preview {
    cursor: pointer;

    &__play {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background 0.3s ease;

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 18px solid white;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            display: inline-block;
            margin-left: 5px;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }

        &:active {
            transform: translate(-50%, -50%) scale(0.9);
        }
    }
}

.video-teaser-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}            

.video-teaser-overlay.open {
    opacity: 1;
    visibility: visible;
}

.video-teaser-overlay__iframe {
    width: 80%;
    height: 80%;
    border: none;
}

.video-teaser-overlay__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    background: none;
    border: none;
}