@import 'grid-loops.less';

@grid-columns: 12;
@grid-gutter: 30px;

.make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: (@grid-gutter / -2);
    margin-left: (@grid-gutter / -2);
}

.row {
    .make-row();
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}

[class*='col-'] {
    padding-right: (@grid-gutter / 2);
    padding-left: (@grid-gutter / 2);
    flex: 0 0 100%;
}

.row--white {
    background: white;
    margin-left: 0;
    margin-right: 0;

    padding-top: (@grid-gutter / 2);
    padding-bottom: (@grid-gutter / 2);

}

.row--white.no-spacing-inside {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.row--lightblue {
    background: #D7E6F0;
}

.vertical-align {
    &--top {
        align-items: flex-start;
    }

    &--center {
        align-items: center;
    }

    &--bottom {
        align-items: flex-end;
    }
}

.make-columns(@grid-columns);

@media @screen-xs-min {
    .make-responsive-columns(@grid-columns, xs);
}
@media @screen-sm-min {
    .make-responsive-columns(@grid-columns, sm);
}
@media @screen-md-min {
    .make-responsive-columns(@grid-columns, md);
}
@media @screen-lg-min {
    .make-responsive-columns(@grid-columns, lg);
}
@media @screen-xl-min {
    .make-responsive-columns(@grid-columns, xl);
}
