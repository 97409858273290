.rauten-teaser {
    position: relative;
    overflow: hidden;
}

.rauten-teaser__canvas_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.rauten-teaser__canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
}

.rauten-teaser__canvas_left {
    @media @screen-md-min {
        height: 140%;
        left: 0;
        bottom: 0;
        background-color: @oebb-red;
        transform-origin: bottom left;
        transform: rotate(42.5deg) scaleX(-1);
    }
}

.rauten-teaser__canvas_right {
    @media @screen-md-min {
        height: 140%;
        right: 0;
        top: 0;
        background-color: @oebb-red;
        transform-origin: top right;
        transform: rotate(42.5deg) scaleX(-1);
    }
}

.carousel--no-canvas {
    .rauten-teaser__canvas_left,
    .rauten-teaser__canvas_right {
        display: none;
    }
}

.rauten-teaser__img {
    .rauten-teaser__img--variation {
        display: none;
    }

    @media @screen-sm-max {
        height: ~'calc(100vh - 110px)';

        > img {
            max-width: none;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }

        .rauten-teaser__img--xs-max {
            display: block;
        }
       
        .rauten-teaser__img--sm-max.rauten-teaser__img--hide-xs,
        .rauten-teaser__img--hide-xs {
            display: none;
        }
    }

    @media @screen-sm-max {
        .rauten-teaser__img--sm-max {
            display: block;
        }
        
        .rauten-teaser__img--hide-sm {
            display: none;
        }
    }


}

.rauten-teaser__link:hover,
.rauten-teaser__link:focus {
    text-decoration: none;
}

.rauten-teaser__text {
    position: absolute;
    bottom: 50px;
    left: 0;
    padding: 10px;
    z-index: 5;
    max-width: 415px;
    width: 100%;

    @media @screen-md-min {
        left: auto;
        right: 0;
        bottom: 100px;
        transform: translateX(-40%);
    }
}



.rauten-teaser__title {
    color: @white;
    margin-bottom: 5px;
    font-size: 32px;
    line-height: 1.25;
    font-family: @font-medium;
    font-weight: 600;

    @media @screen-md-min {
        font-size: ~'calc(32px + 16 * ((100vw - 320px)/ 960))';
    }

    &.lifted {
        text-shadow: 2px 2px 9px rgba(0, 0, 0, 1);
    }

}

.rauten-teaser__subtitle {
    color: @white;
    font-size: 18px;
    margin-bottom: 18px;

    @media @screen-md-min {
        font-size: 22px;
    }
}

.carousel__icon-play:before, .carousel__icon-pause:before {
    color: white;
}
